import type { Path } from 'react-router';
import { parsePath, resolvePath, useLocation, useMatches } from 'react-router';

/**
 * Describes a location that is the destination of some navigation, either via
 * `history.push` or `history.replace`. This may be either a URL or the pieces
 * of a URL path.
 */
export type To = string | Partial<Path>;

function getInvalidPathError(char: string, field: string, dest: string, path: Partial<Path>) {
  return `Cannot include a '${char}' character in a manually specified \`to.${field}\` field [${JSON.stringify(path)}].  Please separate it out to the \`to.${dest}\` field. Alternatively you may provide the full path as a string in <Link to="..."> and the router will parse it for you.`;
}

/**
 * @private
 */
function invariant(value: boolean, message?: string): asserts value {
  if (value === false || value === null || typeof value === 'undefined') {
    throw new Error(message);
  }
}

/**
 * @private
 */
function resolveTo(toArg: To, routePathnames: string[], locationPathname: string, isPathRelative = false): Path {
  let to: Partial<Path>;
  if (typeof toArg === 'string') {
    to = parsePath(toArg);
  } else {
    to = { ...toArg };

    invariant(!to.pathname || !to.pathname.includes('?'), getInvalidPathError('?', 'pathname', 'search', to));
    invariant(!to.pathname || !to.pathname.includes('#'), getInvalidPathError('#', 'pathname', 'hash', to));
    invariant(!to.search || !to.search.includes('#'), getInvalidPathError('#', 'search', 'hash', to));
  }

  const isEmptyPath = toArg === '' || to.pathname === '';
  const toPathname = isEmptyPath ? '/' : to.pathname;

  let from: string;

  // Routing is relative to the current pathname if explicitly requested.
  //
  // If a pathname is explicitly provided in `to`, it should be relative to the
  // route context. This is explained in `Note on `<Link to>` values` in our
  // migration guide from v5 as a means of disambiguation between `to` values
  // that begin with `/` and those that do not. However, this is problematic for
  // `to` values that do not provide a pathname. `to` can simply be a search or
  // hash string, in which case we should assume that the navigation is relative
  // to the current location's pathname and *not* the route pathname.
  if (toPathname == null) {
    from = locationPathname;
  } else {
    let routePathnameIndex = routePathnames.length - 1;

    // With relative="route" (the default), each leading .. segment means
    // "go up one route" instead of "go up one URL segment".  This is a key
    // difference from how <a href> works and a major reason we call this a
    // "to" value instead of a "href".
    if (!isPathRelative && toPathname.startsWith('..')) {
      const toSegments = toPathname.split('/');

      while (toSegments[0] === '..') {
        toSegments.shift();
        routePathnameIndex -= 1;
      }

      to.pathname = toSegments.join('/');
    }

    from = routePathnameIndex >= 0 ? routePathnames[routePathnameIndex]! : '/';
  }

  const path = resolvePath(to, from);

  // Ensure the pathname has a trailing slash if the original "to" had one
  const hasExplicitTrailingSlash = toPathname && toPathname !== '/' && toPathname.endsWith('/');
  // Or if this was a link to the current path which has a trailing slash
  const hasCurrentTrailingSlash = (isEmptyPath || toPathname === '.') && locationPathname.endsWith('/');
  if (!path.pathname.endsWith('/') && (hasExplicitTrailingSlash || hasCurrentTrailingSlash)) {
    path.pathname += '/';
  }

  return path;
}

export const useHasActiveChild = (routes: To[]) => {
  const location = useLocation();
  const matches = useMatches();
  let locationPathname = location.pathname;
  return routes.some(route => {
    if (!route) return false;

    const path = resolveTo(
      route,
      matches.map(m => m.pathname),
      locationPathname,
      false
    );
    let toPathname = path.pathname;

    // eslint-disable-next-line react-compiler/react-compiler
    locationPathname = locationPathname.toLowerCase();
    toPathname = toPathname.toLowerCase();

    return (
      locationPathname === toPathname ||
      (locationPathname.startsWith(toPathname) && locationPathname.charAt(toPathname.length) === '/')
    );
  });
};
