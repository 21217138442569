import type { HTMLAttributes, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import { cn } from '~/libs/utils';

export const SidebarGroup = forwardRef<HTMLUListElement, PropsWithChildren<HTMLAttributes<HTMLUListElement>>>(
  ({ className, ...props }, ref) => (
    <ul
      ref={ref}
      className={cn('space-y-0.5 not-first:mt-1 not-first:border-t not-first:border-border not-first:pt-1', className)}
      {...props}
    />
  )
);
SidebarGroup.displayName = 'SidebarGroup';
