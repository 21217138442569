import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { $path } from 'safe-routes';

import { ApplicationIcon } from '~/components/application-icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';

import { LanguageDropdownMenu } from './language-dropdown-menu';
import { ThemeDropdownMenu } from './theme-dropdown-menu';

const ns = 'header' satisfies Ns;

export const ApplicationSidebarDropdown = () => {
  const { t } = useTranslation(ns);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        data-testid='logo-menu-trigger'
        className='bg-background/80 m-2 flex cursor-pointer items-center justify-stretch gap-2 rounded-lg border p-2 px-4 shadow-sm transition-all hover:bg-background hover:shadow focus-visible:outline-none'
      >
        <h2 className='text-xl font-bold text-foreground xl:text-3xl'>
          <Trans ns={ns} i18nKey='app.title' t={t}>
            Invoicify
          </Trans>
        </h2>
        <ApplicationIcon icon='menu-open' />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>
          <Trans ns={ns} i18nKey='app.title' t={t}>
            Invoicify
          </Trans>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />

        <Link to={$path('/profile')} data-testid='profile-menu-trigger'>
          <DropdownMenuItem>
            <DropdownMenuIcon icon='user' />
            <Trans ns={ns} i18nKey='profile' t={t}>
              View profile
            </Trans>
          </DropdownMenuItem>
        </Link>
        <DropdownMenuSeparator />

        <LanguageDropdownMenu />
        <DropdownMenuSeparator />

        <ThemeDropdownMenu />
        <DropdownMenuSeparator />

        <Link to={$path('/logout')} data-testid='logout-menu-trigger'>
          <DropdownMenuItem>
            <DropdownMenuIcon icon='logout' />
            <Trans ns={ns} i18nKey='logout' t={t}>
              Log out
            </Trans>
          </DropdownMenuItem>
        </Link>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
