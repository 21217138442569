import { LayoutGroup, MotionConfig } from 'framer-motion';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router';
import { $path } from 'safe-routes';

import { cn } from '~/libs/utils';
import type { Route } from '~/types/_auth';

import { SidebarBadge } from './side-nav/sidebar-badge';
import { SidebarGroup } from './side-nav/sidebar-group';
import { SidebarIcon } from './side-nav/sidebar-icon';
import { SidebarElement } from './side-nav/sidebar-link';
import { SidebarSubgroup } from './side-nav/sidebar-sub-group';
import { SidebarText } from './side-nav/sidebar-text';

const ns = 'nav' satisfies Ns;

const InvoiceSidebar = () => {
  const { lateInvoice } = useLoaderData<Route.ComponentProps['loaderData']>();
  const { t } = useTranslation(ns);

  return (
    <SidebarElement data-testid='invoices' to={$path('/invoices')}>
      <SidebarIcon icon='invoice' />
      <SidebarText>
        <Trans ns={ns} i18nKey='invoices' t={t}>
          Invoices
        </Trans>
      </SidebarText>
      {lateInvoice ? <SidebarBadge>{lateInvoice}</SidebarBadge> : null}
    </SidebarElement>
  );
};

export const SideNav = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
  const { t } = useTranslation(ns);
  return (
    <MotionConfig transition={{ duration: 0.1 }} reducedMotion='user'>
      <LayoutGroup id='side-nav'>
        <nav id='side-nav' className={cn('h-full overflow-y-auto', className)} {...props} ref={ref}>
          <SidebarGroup>
            <SidebarElement to={$path('/')}>
              <SidebarIcon icon='home' />
              <SidebarText>
                <Trans ns={ns} i18nKey='home' t={t}>
                  Home
                </Trans>
              </SidebarText>
            </SidebarElement>
            <SidebarElement to={$path('/customers')}>
              <SidebarIcon icon='customer' />
              <SidebarText>
                <Trans ns={ns} i18nKey='customers' t={t}>
                  Customers
                </Trans>
              </SidebarText>
            </SidebarElement>
            <SidebarElement to={$path('/expenses')}>
              <SidebarIcon icon='expense' />
              <SidebarText>
                <Trans ns={ns} i18nKey='expenses' t={t}>
                  Expenses
                </Trans>
              </SidebarText>
            </SidebarElement>
          </SidebarGroup>
          <SidebarGroup>
            <SidebarElement to={$path('/articles')}>
              <SidebarIcon icon='article' />
              <SidebarText>
                <Trans ns={ns} i18nKey='articles' t={t}>
                  Articles
                </Trans>
              </SidebarText>
            </SidebarElement>
            <SidebarElement to={$path('/estimates')}>
              <SidebarIcon icon='estimate' />
              <SidebarText>
                <Trans ns={ns} i18nKey='estimate' t={t}>
                  Estimates
                </Trans>
              </SidebarText>
            </SidebarElement>
            <SidebarElement to={$path('/purchase-orders')}>
              <SidebarIcon icon='purchase-order' />
              <SidebarText>
                <Trans ns={ns} i18nKey='purchase-order' t={t}>
                  Purchase orders
                </Trans>
              </SidebarText>
            </SidebarElement>
            <InvoiceSidebar />
            <SidebarElement to={$path('/credit-notes')}>
              <SidebarIcon icon='credit-note' />
              <SidebarText>
                <Trans ns={ns} i18nKey='credit-note' t={t}>
                  Credit note
                </Trans>
              </SidebarText>
            </SidebarElement>
          </SidebarGroup>
          <SidebarGroup>
            <SidebarElement to={$path('/configuration')}>
              <SidebarIcon icon='configuration' />
              <SidebarText>
                <Trans ns={ns} i18nKey='configuration' t={t}>
                  Configuration
                </Trans>
              </SidebarText>
            </SidebarElement>
            <SidebarSubgroup
              data-testid='technical-side-nav'
              icon='technical'
              text={
                <Trans ns={ns} i18nKey='technical' t={t}>
                  Technical
                </Trans>
              }
            >
              <SidebarElement to={$path('/currencies')}>
                <SidebarIcon icon='currency' />
                <SidebarText>
                  <Trans ns={ns} i18nKey='currencies' t={t}>
                    Currencies
                  </Trans>
                </SidebarText>
              </SidebarElement>
              <SidebarElement to={$path('/vat-types')}>
                <SidebarIcon icon='vat-type' />
                <SidebarText>
                  <Trans ns={ns} i18nKey='vat-types' t={t}>
                    Vat types
                  </Trans>
                </SidebarText>
              </SidebarElement>
              <SidebarElement to={$path('/vat-amounts')}>
                <SidebarIcon icon='vat-amount' />
                <SidebarText>
                  <Trans ns={ns} i18nKey='vat-amounts' t={t}>
                    Vat amounts
                  </Trans>
                </SidebarText>
              </SidebarElement>
              <SidebarElement to={$path('/vat-rates')}>
                <SidebarIcon icon='vat-rate' />
                <SidebarText>
                  <Trans ns={ns} i18nKey='vat-rates' t={t}>
                    Vat rates
                  </Trans>
                </SidebarText>
              </SidebarElement>
              <SidebarElement to={$path('/invoice-status')}>
                <SidebarIcon icon='invoice-status' />
                <SidebarText>
                  <Trans ns={ns} i18nKey='invoice-status' t={t}>
                    Invoice status
                  </Trans>
                </SidebarText>
              </SidebarElement>
              <SidebarElement to={$path('/estimate-status')}>
                <SidebarIcon icon='estimate-status' />
                <SidebarText>
                  <Trans ns={ns} i18nKey='estimate-status' t={t}>
                    Estimate status
                  </Trans>
                </SidebarText>
              </SidebarElement>
              <SidebarElement to={$path('/purchase-order-status')}>
                <SidebarIcon icon='purchase-order-status' />
                <SidebarText>
                  <Trans ns={ns} i18nKey='purchase-order-status' t={t}>
                    Purchase order status
                  </Trans>
                </SidebarText>
              </SidebarElement>
              <SidebarElement to={$path('/credit-note-status')}>
                <SidebarIcon icon='credit-note-status' />
                <SidebarText>
                  <Trans ns={ns} i18nKey='credit-note-status' t={t}>
                    Credit note status
                  </Trans>
                </SidebarText>
              </SidebarElement>
              <SidebarElement to={$path('/emails')}>
                <SidebarIcon icon='email' />
                <SidebarText>
                  <Trans ns={ns} i18nKey='email' t={t}>
                    Emails
                  </Trans>
                </SidebarText>
              </SidebarElement>
            </SidebarSubgroup>
          </SidebarGroup>
        </nav>
      </LayoutGroup>
    </MotionConfig>
  );
});

SideNav.displayName = 'SideNav';
