import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '~/libs/utils';

const SidebarBadgeVariants = cva(
  'ml-3 inline-flex size-3 items-center justify-center rounded-lg border border-muted-foreground p-3 text-sm font-medium',
  {
    variants: {
      variant: {
        default: 'bg-muted text-foreground',
        primary: 'bg-primary text-primary-foreground',
        accent: 'bg-accent text-accent-foreground',
        secondary: 'bg-secondary text-secondary-foreground',
        success: 'bg-success text-success-foreground',
        warning: 'bg-warning text-warning-foreground',
        destructive: 'bg-destructive text-destructive-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);
export const SidebarBadge = forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement> & VariantProps<typeof SidebarBadgeVariants>
>(({ className, variant, ...props }, ref) => (
  <span {...props} className={cn(SidebarBadgeVariants({ variant }), className)} ref={ref} />
));
SidebarBadge.displayName = 'SidebarBadge';
