import { AnimatePresence, motion } from 'framer-motion';

import type { VersionResponse } from '~/.server/api';
import { ApplicationIcon } from '~/components/application-icons';
import { Button } from '~/components/ui/button';
import { useSidebar } from '~/features/layout/use-sidebar';

import { ApplicationSidebarDropdown } from './application-sidebar';
import { Footer } from './footer';
import { SideNav } from './side-nav';

export const ApplicationSidebar = ({ version }: { version: VersionResponse }) => {
  const [open, setOpen] = useSidebar();

  return (
    <AnimatePresence initial={false} mode='popLayout' presenceAffectsLayout>
      {open ? (
        <motion.aside
          initial={{ x: -250 }}
          animate={{ x: 0 }}
          exit={{ x: -250 }}
          transition={{ duration: 0.2 }}
          key='side-bar'
          id='side-bar'
          className='flex flex-col items-stretch justify-between bg-muted text-muted-foreground'
        >
          <div className='relative flex h-full flex-col gap-2'>
            <ApplicationSidebarDropdown />

            <div className='absolute right-3 top-5 xl:top-6'>
              <Button
                className='my-auto h-6 p-2'
                variant='ghost'
                onClick={() => {
                  setOpen(false);
                }}
              >
                <ApplicationIcon icon='panel-close' className='text-foreground' />
              </Button>
            </div>

            <SideNav className='px-2' />
          </div>
          <Footer version={version} className='px-2 pb-8' />
        </motion.aside>
      ) : null}
    </AnimatePresence>
  );
};
