import { Heart } from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';

const ns = 'footer' satisfies Ns;
export const Copyright = () => {
  const { t } = useTranslation(ns);
  return (
    <p className='mx-auto flex items-center whitespace-pre'>
      <Trans ns={ns} i18nKey='copyright' t={t}>
        Made with <Heart className='fill-red-600 stroke-0' /> in Liège.
      </Trans>
    </p>
  );
};
