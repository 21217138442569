import type { HTMLMotionProps } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import type { PropsWithChildren, ReactNode, ReactElement } from 'react';
import { Children, isValidElement, useState } from 'react';
import type { LinkProps } from 'react-router';

import type { ApplicationIconProps } from '~/components/application-icons';
import { ApplicationIcon } from '~/components/application-icons';
import { cn } from '~/libs/utils';

import { elementClassName, linkBaseClassName } from './classes';
import { SidebarIcon } from './sidebar-icon';
import { SidebarText } from './sidebar-text';
import { useHasActiveChild } from './use-has-active-child';

export interface SidebarSubgroupProps extends Partial<Pick<ApplicationIconProps, 'icon'>>, HTMLMotionProps<'li'> {
  text: ReactNode;
  className?: string;
  linkClassName?: string;
}

export const SidebarSubgroup = ({
  text,
  icon,
  children,
  className,
  linkClassName,
  ...props
}: Readonly<PropsWithChildren<SidebarSubgroupProps>>) => {
  const routes =
    Children.map(children as ReactElement<Pick<LinkProps, 'to'>>, child =>
      isValidElement(child) ? child.props : undefined
    )
      ?.map(props => props.to)
      .filter(Boolean) ?? [];
  const initialOpen = useHasActiveChild(routes);
  const [show, setShow] = useState(initialOpen);

  return (
    <>
      <motion.li layout='position' className={cn(elementClassName, className)} {...props}>
        <button
          type='button'
          data-show={show}
          onClick={() => {
            setShow(prev => !prev);
          }}
          className={cn({ active: show }, linkBaseClassName, linkClassName)}
        >
          {icon ? <SidebarIcon icon={icon} /> : null}
          <SidebarText>{text}</SidebarText>
          <ApplicationIcon
            icon='down'
            className='ml-3 size-4 text-muted-foreground transition-transform [.active_&]:-rotate-180'
          />
        </button>
      </motion.li>
      <AnimatePresence initial={false} mode='wait'>
        {show ? (
          <motion.ul
            className='space-y-0.5 pl-2 pt-1'
            layout='position'
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
          >
            {children}
          </motion.ul>
        ) : null}
      </AnimatePresence>
    </>
  );
};
