import { Trans, useTranslation } from 'react-i18next';

import {
  DropdownMenuIcon,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/components/ui/dropdown-menu';
import { getPreferredTheme, useTheme } from '~/features/providers/theme-provider';

import { DropdownMenuItemSelectable } from './dropdown-menu-selectable';

const ns = 'header' satisfies Ns;
export const ThemeDropdownMenu = () => {
  const { t } = useTranslation(ns);
  const [theme, , setTheme] = useTheme();
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger data-testid='theme-menu-trigger'>
        <DropdownMenuIcon icon='theme' />
        <Trans ns={ns} i18nKey='theme.label' t={t}>
          Theme
        </Trans>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          <DropdownMenuItemSelectable
            data-testid='theme-menu-light'
            selected={theme === 'light'}
            onSelect={() => setTheme('light')}
            icon='light-mode'
          >
            <Trans ns={ns} i18nKey='theme.light' t={t}>
              Light
            </Trans>
          </DropdownMenuItemSelectable>
          <DropdownMenuItemSelectable
            data-testid='theme-menu-dark'
            selected={theme === 'dark'}
            onSelect={() => setTheme('dark')}
            icon='dark-mode'
          >
            <Trans ns={ns} i18nKey='theme.dark' t={t}>
              Dark
            </Trans>
          </DropdownMenuItemSelectable>
          <DropdownMenuItemSelectable
            data-testid='theme-menu-system'
            selected={theme === 'system'}
            onSelect={() => setTheme('system')}
            icon='system'
          >
            <Trans ns={ns} i18nKey='theme.system' t={t}>
              System default ({{ theme: getPreferredTheme() }})
            </Trans>
          </DropdownMenuItemSelectable>
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
