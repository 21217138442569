import type { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { VersionResponse } from '~/.server/api';
import {
  Description,
  DescriptionItem,
  DescriptionItemDescription,
  DescriptionItemTitle,
} from '~/components/description';
import { Monospaced } from '~/components/monospaced';
import { Skeleton } from '~/components/ui/skeleton';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/components/ui/tooltip';
import { cn } from '~/libs/utils';

const Code = ({ className, children }: PropsWithChildren<{ className?: string }>) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <pre className={cn('px-1 font-mono text-sm shadow-background', className)}>
          <Monospaced className='text-ellipsis'>{children}</Monospaced>
        </pre>
      </TooltipTrigger>
      <TooltipContent>{children}</TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

const ns = 'footer' satisfies Ns;
const Responsive = () => {
  const { t } = useTranslation(ns);
  return (
    <div className='flex gap-1'>
      <Code className='hidden sm:max-md:block'>
        <Trans ns={ns} i18nKey='sm' t={t}>
          sm
        </Trans>
      </Code>
      <Code className='hidden md:max-lg:block'>
        <Trans ns={ns} i18nKey='md' t={t}>
          md
        </Trans>
      </Code>
      <Code className='hidden lg:max-xl:block'>
        <Trans ns={ns} i18nKey='lg' t={t}>
          lg
        </Trans>
      </Code>
      <Code className='hidden xl:max-2xl:block'>
        <Trans ns={ns} i18nKey='xl' t={t}>
          xl
        </Trans>
      </Code>
      <Code className='hidden 2xl:block'>
        <Trans ns={ns} i18nKey='2xl' t={t}>
          2xl
        </Trans>
      </Code>
    </div>
  );
};

interface SiteInfoValueProps {
  isLoading?: boolean;
  className?: string;
}

const SiteInfoValue = ({ isLoading, className, children }: PropsWithChildren<SiteInfoValueProps>) =>
  isLoading ? (
    <Skeleton className={cn('h-full text-transparent', className)} />
  ) : (
    <Code className={cn('truncate text-xs', className)}>{children}</Code>
  );

const DebugFooter = () => {
  const { i18n, t } = useTranslation(ns);
  return (
    <>
      {import.meta.env.DEV ? (
        <DescriptionItem>
          <DescriptionItemTitle>
            <Trans ns={ns} i18nKey='breakpoint' t={t}>
              Breakpoint
            </Trans>
          </DescriptionItemTitle>
          <DescriptionItemDescription>
            <Responsive />
          </DescriptionItemDescription>
        </DescriptionItem>
      ) : null}
      <DescriptionItem>
        <DescriptionItemTitle>
          <Trans ns={ns} i18nKey='locale' t={t}>
            Locale
          </Trans>
        </DescriptionItemTitle>
        <DescriptionItemDescription>
          <SiteInfoValue>{i18n.language}</SiteInfoValue>
        </DescriptionItemDescription>
      </DescriptionItem>
    </>
  );
};

export const SiteInfo = ({ version: { version, branch, sha1 } }: { version: VersionResponse }) => {
  const { t } = useTranslation(ns);
  return (
    <Description>
      <DescriptionItem>
        <DescriptionItemTitle>
          <Trans ns={ns} i18nKey='version' t={t}>
            Version
          </Trans>
        </DescriptionItemTitle>
        <DescriptionItemDescription>
          <SiteInfoValue>{version}</SiteInfoValue>
        </DescriptionItemDescription>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionItemTitle>
          <Trans ns={ns} i18nKey='branch' t={t}>
            Branch
          </Trans>
        </DescriptionItemTitle>
        <DescriptionItemDescription>
          <SiteInfoValue>{branch}</SiteInfoValue>
        </DescriptionItemDescription>
      </DescriptionItem>
      <DescriptionItem>
        <DescriptionItemTitle>
          <Trans ns={ns} i18nKey='sha' t={t}>
            Sha
          </Trans>
        </DescriptionItemTitle>
        <DescriptionItemDescription>
          <SiteInfoValue>{sha1}</SiteInfoValue>
        </DescriptionItemDescription>
      </DescriptionItem>
      {import.meta.env.DEV ? <DebugFooter /> : null}
    </Description>
  );
};
