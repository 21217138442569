import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFetcher } from 'react-router';
import { $path } from 'safe-routes';

import {
  DropdownMenuIcon,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '~/components/ui/dropdown-menu';

import { DropdownMenuItemSelectable } from './dropdown-menu-selectable';

const ns = 'header' satisfies Ns;

export const LanguageDropdownMenu = () => {
  const { i18n, t } = useTranslation(ns);
  const fetcher = useFetcher();
  const setLanguage = useCallback(
    (locale: string) => {
      fetcher.submit(
        { locale },
        {
          method: 'POST',
          action: $path('/set-locale'),
        }
      );
      i18n.changeLanguage(locale);
    },
    [i18n, fetcher]
  );

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger data-testid='language-menu-trigger'>
        <DropdownMenuIcon icon='language' />
        <Trans ns={ns} i18nKey='language.label' t={t}>
          Language
        </Trans>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          <DropdownMenuItemSelectable
            data-testid='language-menu-fr'
            selected={i18n.language === 'fr'}
            onSelect={() => {
              setLanguage('fr');
            }}
          >
            <div className='mr-1 text-sm' dangerouslySetInnerHTML={{ __html: '&#127463&#127466' }} />
            <Trans ns={ns} i18nKey='language.fr' t={t}>
              French
            </Trans>
          </DropdownMenuItemSelectable>
          <DropdownMenuItemSelectable
            data-testid='language-menu-en'
            selected={i18n.language === 'en'}
            onSelect={() => {
              setLanguage('en');
            }}
          >
            <div className='mr-1 text-sm' dangerouslySetInnerHTML={{ __html: '&#127468&#127463' }} />
            <Trans ns={ns} i18nKey='language.en' t={t}>
              English
            </Trans>
          </DropdownMenuItemSelectable>
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
