import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '~/libs/utils';

export const SidebarText = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(
  ({ className, ...props }, ref) => (
    <span className={cn('ml-3 grow truncate whitespace-nowrap', className)} {...props} ref={ref} />
  )
);
SidebarText.displayName = 'SidebarText';
