import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import type { VersionResponse } from '~/.server/api';
import { cn } from '~/libs/utils';

import { Copyright } from './copyright';
import { SiteInfo } from './site-info';

export const Footer = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    version: VersionResponse;
  }
>(({ className, version, ...props }, ref) => (
  <div className={cn('flex flex-col gap-4', className)} {...props} ref={ref}>
    <SiteInfo version={version} />
    <Copyright />
  </div>
));
Footer.displayName = 'Footer';
