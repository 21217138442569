import type * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import React from 'react';

import type { AppIconNames } from '~/components/application-icons';
import { ApplicationIcon } from '~/components/application-icons';
import { DropdownMenuIcon, DropdownMenuItem, DropdownMenuShortcut } from '~/components/ui/dropdown-menu';

/**
 * DropdownMenuItemSelectable
 * @param selected - is the item selected
 * @param onSelect - callback when the item is selected
 * @param icon - icon to display
 * @param children - children to display
 * @param props - other props
 */
export const DropdownMenuItemSelectable = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
    selected: boolean;
    icon?: AppIconNames;
  }
>(({ selected, icon, children, ...props }, ref) => {
  return (
    <DropdownMenuItem ref={ref} aria-checked={selected} disabled={selected} aria-disabled={selected} {...props}>
      {icon ? <DropdownMenuIcon icon={icon} /> : null}
      {children}
      {selected ? (
        <DropdownMenuShortcut className='ml-2'>
          <ApplicationIcon icon='success' />
        </DropdownMenuShortcut>
      ) : null}
    </DropdownMenuItem>
  );
});
