import type { HTMLMotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import type { PropsWithChildren } from 'react';
import type { LinkProps } from 'react-router';
import { NavLink } from 'react-router';

import { cn } from '~/libs/utils';

import { elementClassName, linkBaseClassName } from './classes';

export type SidebarElementProps = {
  className?: string;
  linkClassName?: string;
} & Pick<LinkProps, 'to'> &
  HTMLMotionProps<'li'>;
export const SidebarElement = ({
  children,
  className,
  linkClassName,
  to,
  ...props
}: Readonly<PropsWithChildren<SidebarElementProps>>) => (
  <motion.li layout='position' className={cn(elementClassName, className)} {...props}>
    <NavLink prefetch='intent' className={cn(linkBaseClassName, linkClassName)} to={to}>
      {children}
    </NavLink>
  </motion.li>
);
